<template>
    <div class="brothers" :class="currentProdFamily.css_class">
        <b-container>
            <b-row>
                <b-col cols="12" lg="7">                
                    <h4>A család többi tagja:</h4>
                    <div class="brothers__slider">
                        <carousel :items="3" :margin="40"  :autoWidth="false" :loop="false" :responsive="{ 
                                0:{
                                    items:2,
                                    autoWidth: true,
                                    margin: 30,
                                    mouseDrag: true,                                 
                                    nav: false},                                
                                992:{items:3, mouseDrag:false, dots:false}
                    }">  
                        <div class="bs-item" 
                            v-for="brother in brothers" :key="brother.id"
                            @click="handleSelectBrother(brother)"
                            :class="{'clicked': selectedBrother.id === brother.id}"
                        >                                          
                            <img v-if="brother.main_image"
                                :src="src_url + itemSrc(brother.main_image, 'w122h260')"                     
                                class="img-fluid"                    
                            />
                            <div class="name">
                                <span class="d-none d-md-inline-block" v-if="currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj">
                                    Soproni Óvatos Duhaj <br/>
                                </span>
                                {{ brother.name }}
                            </div>
                             <router-link 
                                :to="{name:'ProductDetails', params:{product_family_slug:currentProdFamily.slug, product_slug: brother.slug }}"                                 
                                :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj ? 'btn btn--gold btn--with-arrow d-md-none' : 'btn btn--green-border btn--with-arrow d-md-none' ]"
                                @click.native="setGtm(selectedBrother.name)"
                            >
                                <span>Részletek</span>
                            </router-link> 
                        </div>
                    </carousel>                
                    </div>
                    <div class="brothers__desc-wrap d-show--desctop">
                        <h3 class="h1 text--green font-solido">
                            <span v-if="currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj">
                                    Soproni Óvatos Duhaj </span>
                            {{selectedBrother.name}}
                        </h3>
                        <div class="brothers__desc" v-html="selectedBrother.short_description"></div>  
                        <router-link 
                            :to="{name:'ProductDetails', params:{product_family_slug:currentProdFamily.slug, product_slug: selectedBrother.slug }}"                             
                             :class="[currentProdFamily.identifier === $store.state.prodFamiliesId.ovatos_duhaj ? 'btn btn--gold-border btn--with-arrow': 'btn btn--green-border btn--with-arrow' ]"
                             @click.native="setGtm(selectedBrother.name)"
                        >
                            <span>Részletek</span>
                        </router-link>                 
                    </div>
                    
                </b-col>
                <b-col cols="12" lg="5" class="d-none d-lg-block">
                    <div class="brothers__bigImg">
                            <img v-if="selectedBrother.other_in_family_image"
                                :src="src_url + selectedBrother.other_in_family_image.path + '/' + selectedBrother.other_in_family_image.file_name"
                                class="img-fluid brother-img"                    
                            />
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel'

export default {
    props:['brothers', 'currentProdFamily'],
    components:{
        carousel
    },
    data(){
        return{
            selectedBrother: this.brothers[0]
        }
    },
    watch:{
        $route(){
            this.$forceUpdate();
        }
    },
    methods:{
        handleSelectBrother(item){
            this.selectedBrother = item;        
        },
        setGtm(product){            
            let _this = this

            this.GTtrackGA4({
                'event' : 'event',
                'category': _this.currentUrlName,
                'action': 'reszletek_kattintas',                
                'page_type': _this.currentUrlName,
                'button': 'reszletek',            
                'clicked_text': product,
                'action_type': 'gomb_kattintas'
            })
        },
    }
}
</script>